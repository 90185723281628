import React from 'react'

/**
 * Render any children passed to it over a white box with flexbox styling
 * @param children Any valid React component(s)
 * @param justify CSS flexbox justify-content value (default is space-around)
 * @param align CSS flexbox align-items value (default is center)
 */
function WhiteBox({
    children,
    justify = "space-around",
    align = "center",
    padding = 20,
    margin = 0,
    width = null,
}) {

    const boxStyle = {
        width: width ? `${width}px` : "auto",
        height: "auto",
        padding: `${padding}px`,
        margin: `${margin}px`,
        justifyContent: justify,
        alignItems: align,
        backgroundColor: "white",
    }

    return (
        <div className="col" style={boxStyle}>
            {children}
        </div>
    )
}

export default WhiteBox
