import React, { useState, useEffect } from 'react'
import Button from '../../shared/Button'
import useWindowSize from '../../../hooks/useWindowSize';
import { HEADER_HEIGHT } from '../../shared/magicConstants';
import { useHistory } from 'react-router-dom'
import ModelViewer from '../../shared/ModelViewer';
import Headline from '../../shared/Headline';
import WhiteBox from '../../shared/WhiteBox';
import { os } from '../../../services/deviceType';
import Bumper from '../../shared/Bumper'

function Card({ cardObj }) {
    const history = useHistory();
    // Calculate image size based on height if landscape or width if portrait
    const { width, height, aspectRatio } = useWindowSize();
    const size = aspectRatio > 1
        ? (height - HEADER_HEIGHT) * 0.75
        : width * 0.85;

    // Default assumption is device is AR-capable: don't show warning
    const [showWarning, setShowWarning] = useState(false);
    // On load, get device OS. If not Android or iOS, device is not AR capable.
    useEffect(() => {
        const sys = os();
        setShowWarning(sys !== "Android" && sys !== "iOS");
    }, [])
    // Warning to show if device is not AR-capable
    const desktopWarning = {
        title: "Whoa there cowboy...",
        subtitle: "It doesn't look like your device can load this scene in AR. It should automatically play for you above (if it doesn't, try tapping or clicking the image of the card).\n\nTo place the scene in your environment in AR, try loading this page on an AR-capable iOS or Android device."
    }

    return (
        <div className="col" style={{ paddingTop: "20px" }}>
            <div
                className="col"
                style={{
                    height: `${size}px`,
                    width: `${size}px`,
                }}
            >
                <ModelViewer
                    cardObj={cardObj}
                    showButton={!showWarning}
                />
            </div>
            <WhiteBox
                margin={20}
                width={size}
            >
                <Headline
                    title={cardObj.title}
                    subtitle={cardObj.subtitle}
                    size={2}
                />
                {
                    showWarning &&
                    <>
                        <Bumper size={30} />
                        <Headline
                            title={desktopWarning.title}
                            subtitle={desktopWarning.subtitle}
                            size={3}
                            textAlign="left"
                        />
                        <Bumper size={30} />
                    </>
                }
                <Button
                    text="More Cards"
                    size="large"
                    darkBg={false}
                    onClick={() => history.push('/cards')}
                />
                <Button
                    text="Having Trouble?"
                    size="small"
                    darkBg={true}
                    pill={true}
                    onClick={() => history.push('/help')}
                />
            </WhiteBox>
        </div>
    )
}

export default Card
