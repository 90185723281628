import React, { useState } from 'react'
import { IconButton } from '@material-ui/core';
import { NavigateNextRounded } from '@material-ui/icons'
import { NavigateBeforeRounded } from '@material-ui/icons'
import { DEFAULT_TEXT_COLOR } from './magicConstants'

function Carousel({ items = [], iconColor = "", bgOpacity = 0.35 }) {
    // Index of image to display
    const [index, setIndex] = useState(0);

    // Display the next image
    const next = () => {
        const plus = index + 1;
        setIndex(plus < items.length ? plus : 0);
    }

    // Display the previous image
    const previous = () => {
        const minus = index - 1;
        setIndex(minus >= 0 ? minus : items.length - 1);
    }

    // Return either a next or previous button correctly styled and positioned
    const makeButton = (isNext, onClick) => {
        const clr = iconColor ? iconColor : DEFAULT_TEXT_COLOR;
        const bgClr = `rgba(256, 256, 256, ${bgOpacity})`
        const iconStyle = {
            fill: clr,
            margin: "10px",
        }
        const bgStyle = {
            background: bgClr,
            border: `0px solid white`, // ie no border
            borderRadius: "1000px",
        }
        const icon = () => {
            return isNext
                ? <NavigateNextRounded
                    fontSize="large"
                    style={iconStyle}
                />
                : <NavigateBeforeRounded
                    fontSize="large"
                    style={iconStyle}
                />
        }
        const positionStyle = {
            position: "absolute",
            width: "auto",
            height: "auto",
            right: isNext ? "0px" : null,
            left: isNext ? null : "0px",
            justifyContent: isNext ? "flex-end" : "flex-start",
            // Value of 10 is arbitrary but the buttons will disappear behind
            // any model-viewer element if zIndex left to default
            zIndex: "10",
        }
        return (
            <div className="row" style={positionStyle}>
                <IconButton onClick={onClick}>
                    <div className="col" style={bgStyle}>
                        {icon()}
                    </div>
                </IconButton>
            </div>
        )
    }

    return (
        <div className="row" style={{ position: "relative" }}>
            {makeButton(false, previous)}
            {items[index]}
            {makeButton(true, next)}
        </div>
    )
}

export default Carousel
