import React from 'react'
import { fontSizes } from '../../shared/magicConstants'
import useResponseFactor from '../../../hooks/useResponseFactor'

function Faq({ qaPair }) {
    const resFactor = useResponseFactor();

    const qStyle = {
        fontSize: `${fontSizes.title.small * resFactor}px`,
        fontFamily: "amity",
        fontWeight: "normal",
        width: "100%",
        textAlign: "left",
        marginBottom: "5px",
    }

    const aStyle = {
        fontSize: `${fontSizes.subtitle.small * resFactor}px`,
        width: "100%",
        textAlign: "left",
        whiteSpace: "pre-line",
    }

    return (
        <div
            className="col"
            style={{
                width: "100%",
                marginBottom: "40px",
            }}
        >
            <h3 style={qStyle}>
                {qaPair.q}
            </h3>
            <p style={aStyle}>
                {qaPair.a}
            </p>
        </div>
    )
}

export default Faq
