export const tags = [
    "Holiday",
    "Birthday",
    "Special Occasion",
    "Anniversary",
];

export const cards = [
    {
        title: "Naughty Or Nice",
        subtitle: "Be the Belle of the Ball Gag this holiday season!",
        price: "$5.99",
        etsyLink: "https://www.etsy.com/listing/897781936/naughty-or-nice-funny-augmented-reality",
        usdz: "https://firebasestorage.googleapis.com/v0/b/little-idiots.appspot.com/o/models%2Fnaughty.usdz?alt=media",
        glb: "https://firebasestorage.googleapis.com/v0/b/little-idiots.appspot.com/o/models%2Fnaughty.glb?alt=media",
        alt: "Have you been naughty or nice this year?",
        cardPage: "naughty",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/little-idiots.appspot.com/o/images%2Fnaughty-card.png?alt=media",
            "https://firebasestorage.googleapis.com/v0/b/little-idiots.appspot.com/o/images%2Fnaughty-card-scene.png?alt=media",
        ],
        tags: [
            "Holiday",
        ]
    },
    {
        title: "Give / Receive",
        subtitle: "Send one to your grandma!",
        price: "$5.99",
        etsyLink: "https://www.etsy.com/listing/911706313/better-to-give-funny-augmented-reality",
        usdz: "https://firebasestorage.googleapis.com/v0/b/little-idiots.appspot.com/o/models%2Fgive-receive.usdz?alt=media",
        glb: "https://firebasestorage.googleapis.com/v0/b/little-idiots.appspot.com/o/models%2Fgive-receive.glb?alt=media",
        alt: "Some say it's better to give than to receive.",
        cardPage: "give-receive",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/little-idiots.appspot.com/o/images%2Fgive-receive-card.png?alt=media",
            "https://firebasestorage.googleapis.com/v0/b/little-idiots.appspot.com/o/images%2Fgive-receive-card-scene.png?alt=media",
        ],
        tags: [
            "Holiday",
        ]
    },
    {
        title: "Deck The Halls",
        subtitle: "These guys are out, proud, and ready to rock the holidays!\nSend one to your favorite leather daddy!",
        price: "$5.99",
        etsyLink: "https://www.etsy.com/listing/897776302/deck-the-halls-funny-augmented-reality",
        usdz: "https://firebasestorage.googleapis.com/v0/b/little-idiots.appspot.com/o/models%2Fdeck-the-halls.usdz?alt=media",
        glb: "https://firebasestorage.googleapis.com/v0/b/little-idiots.appspot.com/o/models%2Fdeck-the-halls.glb?alt=media",
        alt: "It's time to deck the halls!",
        cardPage: "deck-the-halls",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/little-idiots.appspot.com/o/images%2Fdeck-the-halls-card.png?alt=media",
            "https://firebasestorage.googleapis.com/v0/b/little-idiots.appspot.com/o/images%2Fdeck-the-halls-card-scene.png?alt=media",
        ],
        tags: [
            "Holiday",
        ]
    },
    {
        title: "Milk & Cookies",
        subtitle: "Santa's plastered!\nSend one to your parole officer!",
        price: "$5.99",
        etsyLink: "https://www.etsy.com/listing/897780644/milk-and-cookies-funny-augmented-reality",
        usdz: "https://firebasestorage.googleapis.com/v0/b/little-idiots.appspot.com/o/models%2Fmilk-and-cookies.usdz?alt=media",
        glb: "https://firebasestorage.googleapis.com/v0/b/little-idiots.appspot.com/o/models%2Fmilk-and-cookies.glb?alt=media",
        alt: "Santa doesn't want milk and cookies.",
        cardPage: "milk-and-cookies",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/little-idiots.appspot.com/o/images%2Fmilk-and-cookies-card.png?alt=media",
            "https://firebasestorage.googleapis.com/v0/b/little-idiots.appspot.com/o/images%2Fmilk-and-cookies-card-scene.png?alt=media",
        ],
        tags: [
            "Holiday",
        ]
    },
    {
        title: "Mrs Claus",
        subtitle: "Because you're never too old to black out at a work event!",
        price: "$5.99",
        etsyLink: "https://www.etsy.com/listing/897783290/mrs-claus-dancing-funny-augmented",
        usdz: "https://firebasestorage.googleapis.com/v0/b/little-idiots.appspot.com/o/models%2Fnorth-pole.usdz?alt=media",
        glb: "https://firebasestorage.googleapis.com/v0/b/little-idiots.appspot.com/o/models%2Fnorth-pole.glb?alt=media",
        alt: "When Mrs Claus gets drunk and starts dancing at the Christmas party...",
        cardPage: "north-pole",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/little-idiots.appspot.com/o/images%2Fnorth-pole-card.png?alt=media",
            "https://firebasestorage.googleapis.com/v0/b/little-idiots.appspot.com/o/images%2Fnorth-pole-card-scene.png?alt=media",
        ],
        tags: [
            "Holiday",
        ]
    },
    {
        title: "Anniversary",
        subtitle: "Sex!\nSend one to the ol' ball and chain!",
        price: "$5.99",
        etsyLink: "https://www.etsy.com/listing/911689439/its-our-anniversary-funny-augmented",
        usdz: "https://firebasestorage.googleapis.com/v0/b/little-idiots.appspot.com/o/models%2Fanniversary.usdz?alt=media",
        glb: "https://firebasestorage.googleapis.com/v0/b/little-idiots.appspot.com/o/models%2Fanniversary.glb?alt=media",
        alt: "It's our anniversary.",
        cardPage: "anniversary",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/little-idiots.appspot.com/o/images%2Fanniversary-card.png?alt=media",
            "https://firebasestorage.googleapis.com/v0/b/little-idiots.appspot.com/o/images%2Fanniversary-card-scene.png?alt=media",
        ],
        tags: [
            "Anniversary",
        ]
    },
    {
        title: "How Much",
        subtitle: "Send one to someone who won't know if you're joking!",
        price: "$5.99",
        etsyLink: "https://www.etsy.com/listing/911696533/its-your-birthday-funny-augmented",
        usdz: "https://firebasestorage.googleapis.com/v0/b/little-idiots.appspot.com/o/models%2Fhow-much.usdz?alt=media",
        glb: "https://firebasestorage.googleapis.com/v0/b/little-idiots.appspot.com/o/models%2Fhow-much.glb?alt=media",
        alt: "It's your birthday!",
        cardPage: "how-much",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/little-idiots.appspot.com/o/images%2Fhow-much-card.png?alt=media",
            "https://firebasestorage.googleapis.com/v0/b/little-idiots.appspot.com/o/images%2Fhow-much-card-scene.png?alt=media",
        ],
        tags: [
            "Birthday",
        ]
    },
    {
        title: "One Job",
        subtitle: "And what a hard job it is! Lol!",
        price: "$5.99",
        etsyLink: "https://www.etsy.com/listing/897771064/one-job-funny-augmented-reality-greeting",
        usdz: "https://firebasestorage.googleapis.com/v0/b/little-idiots.appspot.com/o/models%2Fone-job.usdz?alt=media",
        glb: "https://firebasestorage.googleapis.com/v0/b/little-idiots.appspot.com/o/models%2Fone-job.glb?alt=media",
        alt: "On your special day, I have just one job.",
        cardPage: "one-job",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/little-idiots.appspot.com/o/images%2Fone-job-card.png?alt=media",
            "https://firebasestorage.googleapis.com/v0/b/little-idiots.appspot.com/o/images%2Fone-job-card-scene.png?alt=media",
        ],
        tags: [
            "Birthday", "Special Occasion",
        ]
    },
    {
        title: "Perfect Present",
        subtitle: "Send one to your cell mate!",
        price: "$5.99",
        etsyLink: "https://www.etsy.com/listing/897769662/just-the-right-present-funny-augmented",
        usdz: "https://firebasestorage.googleapis.com/v0/b/little-idiots.appspot.com/o/models%2Fperfect-present.usdz?alt=media",
        glb: "https://firebasestorage.googleapis.com/v0/b/little-idiots.appspot.com/o/models%2Fperfect-present.glb?alt=media",
        alt: "I found just the right present for your special day!",
        cardPage: "perfect-present",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/little-idiots.appspot.com/o/images%2Fperfect-present-card.png?alt=media",
            "https://firebasestorage.googleapis.com/v0/b/little-idiots.appspot.com/o/images%2Fperfect-present-card-scene.png?alt=media",
        ],
        tags: [
            "Birthday", "Special Occasion",
        ]
    },
    {
        title: "Wino",
        subtitle: "Send one to your passed out girlfriend!",
        price: "$5.99",
        etsyLink: "https://www.etsy.com/listing/911701447/happy-birthday-wino-funny-augmented",
        usdz: "https://firebasestorage.googleapis.com/v0/b/little-idiots.appspot.com/o/models%2Fwino.usdz?alt=media",
        glb: "https://firebasestorage.googleapis.com/v0/b/little-idiots.appspot.com/o/models%2Fwino.glb?alt=media",
        alt: "Happy birthday, wino.",
        cardPage: "wino",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/little-idiots.appspot.com/o/images%2Fwino-card.png?alt=media",
            "https://firebasestorage.googleapis.com/v0/b/little-idiots.appspot.com/o/images%2Fwino-card-scene.png?alt=media",
        ],
        tags: [
            "Birthday",
        ]
    },
]

export const faqs = [
    {
        q: "I can't open the scene in AR.",
        a: "Make sure you're using an AR-capable iOS or Android device and that you're running the latest version of iOS or Android. The scene will take a moment to load after you tap the icon. You may have trouble if you're not using Safari on iOS or Chrome on Android."
    },
    {
        q: "The AR scene tries to open but never finds a surface to place the objects.",
        a: "It might be too dark where you are, or you might need to find a horizontal surface with more detail and visual texture.\n\nIt's possible, though unlikely, that this is the universe punishing you for misdeeds in this or a previous life. Try turning off your karma and turning it back on again."
    },
    {
        q: "The AR scene moves around after I place it.",
        a: "It might be too dark where you are, or you might need to find a horizontal surface with more detail and visual texture. You can also try moving your device around in space more, to help the device see more of your environment."
    },
    {
        q: "I don't know how to scan a QR code with my phone.",
        a: "Ok Boomer, no problem. First of all, that's not a phone, that's a fax machine. Second, it's not plugged in. Third... you know what? Just find the nearest avocado toast restaurant and ask a Millennial for help."
    },
    {
        q: "I am unhappy.",
        a: "If you have a problem with an order you've placed, please contact us through the Etsy Store and we will take care of it ASAP.\n\nIf it's not a problem with an order, email contact@littleidiots.com.",
    },
    {
        q: "No, I mean unhappy in life, in general.",
        a: "Have you tried buying some of our cards? Laughter might temporarily distract you from the meaninglessness of life, certainty of death, and taste of ash that these can leave in your mouth. Giving a present to a loved one - for example, a tasteless AR greeting card - might make you feel more connected and loving. And if nothing else, a century of western consumerism has certainly taught us that the more crap you buy, the happier you'll be. So buy a few!"
    },
    {
        q: "I am offended.",
        a: "Have you tried buying another card? You might find it less offensive. Keep buying cards until 1) you find one you like, in which case you can declare victory, or 2) you've bought all the cards and hate them all, in which case this might not be the right product for you, but at least Little Idiots has made some money figuring that out."
    },
    {
        q: "You should be ashamed of yourself.",
        a: "Way ahead of you."
    },
    {
        q: "Seriously, this is in horrible taste and you should be canceled.",
        a: "If you're going to boycott us please do so very loudly and publicly, and when you do, link directly to our Shop Cards page or Etsy Store."
    },
    {
        q: "I have another problem that isn't covered here.",
        a: "You can email us at contact@littleidiots.com."
    },
    {
        q: "I might be in the wrong place. I need help because I am being attacked by a Sasquatch.",
        a: "Curl up into a ball and use your hands and arms to protect your head, neck, and vital organs.\n\nTry alternating between making high pitched squealing noises and quacking like a duck. If the Sasquatch gets confused or weirded out, maybe he'll stop.\n\nAs a last resort you can try challenging the Sasquatch to a battle of wits instead of a mauling, but this may backfire if he's smarter than you are.\n\nGood luck!"
    },
]
