import React from 'react'
import { Link } from 'react-router-dom'
import './foot.css'

/**
 * @param links Array of objects with format {to: "/dir/dir/target", text: "Name of Link"}
 * @param email Email address
 * @param copyright Object with format {year: "2021", name: "Ingrate, LLC"}
 */
function Footer({
    config: {
        links = [],
        email = "",
        copyright = null,
    }
}) {
    return (
        <div className="col footer unstyle-links">
            <ul>
                {links.map((l, i) => {
                    return (
                        <Link key={i} to={l.to}>
                            <li>{l.text}</li>
                        </Link>
                    )
                })}
                {email && <li><a href={`mailto: ${email}`}>{email}</a></li>}
                <li>Copyright &copy; {copyright?.year || ""} {copyright?.holder || ""}</li>
            </ul>
        </div>
    )
}

export default Footer
