// Component that holds the actual list of links for the header menu

import React from 'react'
import { Link } from 'react-router-dom';
//import { useAuth } from '../../../contexts/AuthContext.js';

function NavLinks({ closeMenu }) {
    //const { currentUser } = useAuth();

    const onClickLink = () => {
        if (closeMenu) {
            closeMenu();
        }
    }

    const links = [
        { link: "/", text: "Home" },
        { link: "/cards", text: "Cards" },
        { link: "/help", text: "Help" },
        // { link: currentUser ? "/account" : null, text: "Account" },
        // { link: currentUser ? null : "/create-account", text: "Create Account" },
        // { link: currentUser ? null : "/sign-in", text: "Sign In" },
    ]

    return (
        <ul className="row nav-links">
            {links.map((link, index) => {
                return (
                    link.link
                        ? <Link
                            key={index}
                            to={link.link}
                            onClick={onClickLink}>
                            <li className="nav-link">{link.text}</li>
                        </Link>
                        : null
                )
            })}
        </ul>
    )
}

export default NavLinks