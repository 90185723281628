import React from 'react'
import WhiteBox from '../../shared/WhiteBox'
import Headline from '../../shared/Headline'
import useWindowSize from '../../../hooks/useWindowSize'
import CardView from './CardView'
import useResponseFactor from '../../../hooks/useResponseFactor'
import { breakpoints, MAX_PAGE_WIDTH } from '../../shared/magicConstants'

/**
 * Renders a section of cards on the browse cards page.
 * @param cards List of card objects to display.
 * @param title The title of the section (eg Holiday, Birthday, Anniversary).
 */
function CardSection({ cards, title }) {
    const BASE_CARD_WIDTH = 400;
    const { width } = useWindowSize();
    const resFactor = useResponseFactor() || 1.0;
    const thisDivWidth = Math.min(width * 0.95, MAX_PAGE_WIDTH);
    // If on tablet or phone, card width is 85% of screen (creates a column)
    // Otherwise card width is < 1/3 of screen (creates rows of up to 3)
    const cardWidth = width > breakpoints.tablet
        ? Math.max(BASE_CARD_WIDTH, thisDivWidth * 0.3)
        : Math.min(BASE_CARD_WIDTH * resFactor, 0.85 * width);

    // Figure out how many cards will fit in this div, then pad the end of the
    // cards list with spaceholder divs. This creates the illusion of a grid for
    // the last row of cards.
    const cardsPerRow = Math.floor(thisDivWidth / cardWidth);
    const lastRowRemainder = cards.length % cardsPerRow;
    if (lastRowRemainder !== 0) {
        for (let i = 0; i < cardsPerRow - lastRowRemainder; i++) {
            cards.push(null);
        }
    }

    return (
        <div
            className="col"
            style={{
                border: "3px solid black",
                width: "100%",
                padding: `${0.05 * width}`,
                marginBottom: "40px",
            }}
        >
            <div
                className="row"
                style={{
                    width: "100%",
                    justifyContent: "center",
                    margin: "20px 0px",
                }}
            >
                <WhiteBox>
                    <Headline title={title} size={2} />
                </WhiteBox>
            </div>
            <div
                className="row to-col-phone"
                style={{
                    flexWrap: "wrap",
                    justifyContent: "space-around"
                }}
            >
                {cards.map((c, i) => {
                    // If it's a real card, return a CardView component
                    // If null, return a spaceholder div the size of a CardView
                    return c !== null
                        ? <CardView
                            key={c.cardPage}
                            card={c}
                            width={cardWidth}
                        />
                        : <div
                            key={i}
                            style={{
                                width: `${cardWidth}px`
                            }}
                        />
                })}
            </div>
        </div>
    )
}

export default CardSection
