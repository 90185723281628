export const footerConfig = {
    links: [
        {
            to: "/legal/terms",
            text: "Terms of Service",
        },
        {
            to: "/legal/privacy",
            text: "Privacy Policy",
        },
    ],
    email: "contact@littleidiots.com",
    copyright: {
        year: "2020 - 2021",
        holder: "Ingrate, LLC",
    }
}