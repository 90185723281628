import React from 'react'
import Headline from '../../shared/Headline'
import WhiteBox from '../../shared/WhiteBox'
import CardSection from './CardSection'
import { cards, tags } from '../../shared/fakeBackEnd'

function Cards() {
    return (
        <div
            className="col"
            style={{
                width: "95vw",
                maxWidth: "var(--max-page-width)",
            }}
        >
            <WhiteBox margin={20}>
                <Headline title="Greeting Cards" size={1} />
            </WhiteBox>
            {
                tags.map(t => {
                    const matches = cards.filter(c => c.tags.includes(t));
                    return (
                        <CardSection
                            key={t}
                            cards={matches}
                            title={t}
                        />
                    )
                })
            }
        </div>
    )
}

export default Cards
