// Returns an invalid url message.

import React from 'react';
import Bumper from './Bumper';

function InvalidUrl() {
    return (
        <div className="col invalid-url">
            <img
                src={"https://firebasestorage.googleapis.com/v0/b/little-idiots.appspot.com/o/images%2Fsorry.gif?alt=media"}
                alt={"sorry..."}
            />
            <Bumper size={40} />
            <h1>File Not Found</h1>
            <Bumper size={40} />
            {<h3>There doesn't seem to be anything here.</h3>}
        </div>
    )
}

export default InvalidUrl
