import React from 'react'
import useWindowSize from '../../hooks/useWindowSize'
import WhiteBox from './WhiteBox';
import Headline from './Headline';
import Button from './Button';

function Showcase({
    image,
    imageAlt,
    headline,
    buttonText,
    onButtonClick,
}) {
    // Use window dimensions to get aspect ratio 
    const { width, height } = useWindowSize();
    // Screen orientation is landscape (or square), otherwise portrait
    const landscape = width * 1.0 / height >= 1;

    const divStyle = {
        width: "95vw",
        maxWidth: "var(--max-page-width)",
        height: "var(--full-page-height)",
    }

    // If landscape, image is 50% of adjusted screen height, auto width
    // If portrait, image is full width, auto height
    const imgStyle = () => {
        return landscape
            ? {
                height: "calc(var(--full-page-height) * 0.5)",
                width: "auto",
            }
            : {
                width: "100%",
                height: "auto",
            }
    }

    return (
        <div
            className="col"
            style={divStyle}
        >
            <img
                src={image}
                alt={imageAlt}
                style={imgStyle()}
            />
            <WhiteBox>
                <Headline
                    title={headline.title || ""}
                    subtitle={headline.subtitle || ""}
                    footnote={headline.footnote || ""}
                    size={headline.size || 1}
                    maxBoxWidth={headline.maxBoxWidth || null}
                    maxSubtitleWidth={headline.maxSubtitleWidth || null}
                />
            </WhiteBox>
            <Button
                size={"large"}
                text={buttonText}
                darkBg={true}
                onClick={onButtonClick}
            />
        </div>
    )
}

export default Showcase
