import React from 'react'
import { faqs } from '../../shared/fakeBackEnd'
import Headline from '../../shared/Headline'
import WhiteBox from '../../shared/WhiteBox'
import Faq from './Faq'

function Help() {
    return (
        <div className="col">
            <WhiteBox margin={20}>
                <Headline title="Get Help" size={1} />
            </WhiteBox>
            <div
                className="col"
                style={{
                    width: "95vw",
                    maxWidth: "800px",
                    height: "auto",
                    minHeight: "10px",
                    padding: "20px",
                    backgroundColor: "white",
                }}
            >
                {faqs.map((f, i) => {
                    return <Faq qaPair={f} key={i} />
                })}
            </div>
        </div>
    )
}

export default Help
