import React from 'react'
import { BACKGROUND_COLOR } from './magicConstants'
import Button from '../shared/Button'
import placeholder from '../../images/placeholder.png'

function ModelViewer({ cardObj, bgColor = BACKGROUND_COLOR, showButton }) {
    const style = {
        height: "100%",
        width: "100%",
        backgroundColor: bgColor,
        "--poster-color": bgColor,
        // Without setting progress mask to transparent, there will be a white
        // filmy layer over the poster.
        "--progress-mask": "rgba(0,0,0,0)",
    }

    return (
        <model-viewer
            style={style}
            id={cardObj.cardPage}
            src={cardObj.glb}
            ios-src={cardObj.usdz}
            alt={cardObj.alt}
            autoplay
            ar
            ar-modes="webxr scene-viewer quick-look"
            auto-rotate
            camera-controls
            // Adjust cam target and FOV to center (most of) the models
            camera-target="0m 0.1m -0.05m"
            field-of-view="40deg"
            reveal={showButton ? "interaction" : "auto"}
            poster={cardObj.images[0] || placeholder}
        >
            {
                showButton &&
                <div
                    className="col"
                    style={{
                        justifyContent: "flex-end",
                    }}
                    slot="ar-button"
                >
                    <Button
                        text="Open In AR"
                        darkBg={true}
                        pill={false}
                        size={"large"}
                    />
                </div>
            }
        </model-viewer>
    )
}

export default ModelViewer
