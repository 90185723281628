import React from 'react'
import Headline from '../../shared/Headline'
import WhiteBox from '../../shared/WhiteBox'
import { breakpoints } from '../../shared/magicConstants'
import useWindowSize from '../../../hooks/useWindowSize'
import Button from '../../shared/Button'
import { useHistory } from 'react-router'
import Bumper from '../../shared/Bumper'

function HowItWorks({ scrollToTryIt }) {
    const { width } = useWindowSize();
    const rows = width > breakpoints.tablet;
    const imgWidth = rows ? "50%" : width > breakpoints.phone ? "75%" : "100%";
    const history = useHistory();

    const partOne = () => {
        return (
            <>
                <WhiteBox>
                    <Headline
                        title="1. Buy A Card"
                        subtitle="They're physical greeting cards, just like what your grandma sends you in the mail.*"
                        footnote="*Except darker, weirder, and borderline illegal in places like Saudi Arabia, North Korea, and Texas."
                        size={3}
                        textAlign="left"
                    />
                    <Bumper size={10} />
                    <Button
                        text="Shop Cards"
                        onClick={() => history.push("/cards")}
                        darkBg={true}
                        pill={true}
                        size={"small"}
                    />
                </WhiteBox>
                <img
                    src={"https://firebasestorage.googleapis.com/v0/b/little-idiots.appspot.com/o/images%2Ffive-cards.png?alt=media"}
                    alt="Little Idiots cards"
                    style={{
                        width: imgWidth,
                        height: "auto",
                        //border: "1px solid black",
                    }}
                />
            </>
        );
    }

    const partTwo = () => {
        const step2 = <WhiteBox>
            <Headline
                title="2. Scan the QR Code"
                subtitle="Follow the link to open the AR scene in your browser, then follow the instructions to place the scene in your environment."
                footnote="You must have an AR-capable iOS or Android device to open the scene in AR. The AR scene may not work correctly in all environments or on all devices."
                size={3}
                textAlign="left"
            />
            <Bumper size={10} />
            <Button
                text="Having Trouble?"
                onClick={() => history.push("/help")}
                darkBg={true}
                pill={true}
                size={"small"}
            />
        </WhiteBox>

        const step3 = <WhiteBox>
            <Headline
                title="3. Laugh Your Ass Off"
                subtitle="Give yourself a pat on the back! You did it!"
                size={3}
                textAlign="left"
            />
            <Bumper size={10} />
            <Button
                text="Try It For Free"
                onClick={scrollToTryIt}
                darkBg={true}
                pill={true}
                size={"small"}
            />
        </WhiteBox>

        const img = <img
            src={"https://firebasestorage.googleapis.com/v0/b/little-idiots.appspot.com/o/images%2Fgive-receive-with-phone.png?alt=media"}
            alt="Some say it's better to give than to receive."
            style={{
                width: imgWidth,
                height: "auto",
            }}
        />

        return (
            rows
                ? <>{img}<div className="col">{step2}<Bumper size={20} />{step3}</div></>
                : <>{step2}<Bumper size={20} />{img}<Bumper size={20} />{step3}</>
        )
    }

    return (
        <div
            className="col"
            style={{
                border: "3px solid black",
                width: "95vw",
                maxWidth: "1100px",
                padding: "40px 20px",
                margin: "30px 0px",
            }}
        >
            <WhiteBox>
                <Headline
                    title="How It Works"
                    size={2}
                />
            </WhiteBox>
            <Bumper size={20} />
            <div
                className="row to-col-tablet"
                style={{
                    margin: "10px 0px",
                    width: "100%"
                }}
            >
                {partOne()}
            </div>
            <div
                className="row to-col-tablet"
                style={{
                    margin: "10px 0px",
                    width: "100%"
                }}
            >
                {partTwo()}
            </div>
        </div>
    )
}

export default HowItWorks
