// Context/functions for current user, signing in and out, registering, password reset, etc
/*
https://www.youtube.com/watch?v=PKwu15ldZ7k
*/

import React, { useContext, useState, useEffect } from 'react'
//import { auth, saveUpdatedEmail, setUpNewUserAcctDoc } from '../services/firebase/firebase.js'

const AuthContext = React.createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState();
    const [loading, setLoading] = useState(true); // loading is true by default, until getting user

    useEffect(() => {
        // Set currentUser to be either null (to see signed out behavior)
        // or fake (to see signed in behavior)
        setCurrentUser(null);
        // setCurrentUser({ uid: "fakeUidString", email: "fake@fake.fake" });
        setLoading(false);
        // const unsubscribe = auth.onAuthStateChanged(user => {
        //     setCurrentUser(user);
        //     setLoading(false); // once you've got the user, loading is false
        // })
        // return unsubscribe;
    }, [])

    // async function register(email, password, firstName, lastName) {
    //     return await auth.createUserWithEmailAndPassword(email, password)
    //         .then(async userCredential => {
    //             return await setUpNewUserAcctDoc({ email, userId: userCredential.user.uid, firstName, lastName })
    //         }, error => {
    //             console.log("error registering: ", error);
    //             throw error;
    //         })
    // }

    // function signIn(email, password) {
    //     return auth.signInWithEmailAndPassword(email, password);
    // }

    // function signOut() {
    //     return auth.signOut();
    // }

    // function resetPassword(email) {
    //     return auth.sendPasswordResetEmail(email);
    // }

    // async function updateEmailAndPassword({ newEmail, newPassword, oldPassword }) {
    //     try {
    //         await auth.signInWithEmailAndPassword(currentUser.email, oldPassword)
    //             .then(async (userCredential) => {
    //                 if (newEmail) {
    //                     await userCredential.user.updateEmail(newEmail);
    //                     await saveUpdatedEmail(newEmail);
    //                 }
    //                 if (newPassword) {
    //                     await userCredential.user.updatePassword(newPassword);
    //                 }
    //             }).catch(error => {
    //                 throw error;
    //             })
    //         return { error: false }
    //     } catch (error) {
    //         return {
    //             error:
    //                 { message: "Could not update." }
    //         }
    //     }
    // }

    const value = {
        currentUser,
        // register,
        // signIn,
        // signOut,
        // resetPassword,
        // updateEmailAndPassword,
    }

    // Only render children if loading is false - ie, after we get user
    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    )
}