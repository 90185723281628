// Placeholder Home page

import React, { useRef } from 'react'
import Bumper from '../../shared/Bumper'

import Showcase from '../../shared/Showcase'
import MiniShowcase from '../../shared/MiniShowcase'
import { useHistory } from 'react-router-dom'
import WhiteBox from '../../shared/WhiteBox'
import Headline from '../../shared/Headline'
import Button from '../../shared/Button'
import HowItWorks from './HowItWorks'

//import { getOS } from '../../../services/os'

function Home() {
    const history = useHistory();
    // Reference to the "How It Works" section of the home page
    const worksRef = useRef(null);
    // Reference to the "Try It" section of the home page
    const tryItRef = useRef(null);

    const scrollToRef = (ref) => ref.current.scrollIntoView();

    const miniShowcaseBox1 = {
        title: "Amazing Technology",
        subtitle: "Scan the QR code with an iOS or Android device to launch the AR scene.\nNo app to download.",
        buttonText: "How It Works",
        onButtonClick: () => scrollToRef(worksRef),// worksRef.current.scrollIntoView(),
    }

    const miniShowcaseBox2 = {
        title: "Amazingly Bad Taste",
        subtitle: "Immaturity! Sexual Innuendo! Turds!\nHorrify your unsuspecting victims!\nScar your loved ones for life!",
        buttonText: "Try It For Free",
        onButtonClick: () => scrollToRef(tryItRef),// tryItRef.current.scrollIntoView(),
    }

    const miniShowcaseImg = (img, altText) => {
        return (
            <img
                src={img}
                alt={altText}
                style={{
                    maxHeight: "100%",
                    maxWidth: "100%",
                }}
            />
        );
    }

    const miniShowcaseBox = ({ title, subtitle, buttonText, onButtonClick }) => {
        return (
            <WhiteBox>
                <Headline
                    title={title}
                    subtitle={subtitle}
                    size={3}
                    maxBoxWidth={400}
                />
                <Button
                    size={"medium"}
                    text={buttonText}
                    pill={true}
                    darkBg={false}
                    onClick={onButtonClick}
                />
            </WhiteBox>
        );
    }

    return (
        <div
            className="col"
            style={{
                width: "95vw",
                maxWidth: "var(--max-page-width)",
            }}
        >
            <Showcase
                image={"https://firebasestorage.googleapis.com/v0/b/little-idiots.appspot.com/o/images%2Fperfect-present-showcase.png?alt=media"}
                imageAlt="I found the perfect present for your special day."
                headline={{
                    title: "Idiotic Greeting Cards",
                    subtitle: "Brought to life in AR",
                    maxSubtitleWidth: 450,
                }}
                buttonText="Shop Cards"
                onButtonClick={() => history.push("/cards")}
            />
            <MiniShowcase
                box={miniShowcaseBox(miniShowcaseBox1)}
                image={
                    miniShowcaseImg(
                        "https://firebasestorage.googleapis.com/v0/b/little-idiots.appspot.com/o/images%2Fnaughty-card-scene-simple.png?alt=media",
                        "Have you been naughty or nice?"
                    )
                }
            />
            <Bumper size={20} />
            <MiniShowcase
                box={miniShowcaseBox(miniShowcaseBox2)}
                image={
                    miniShowcaseImg(
                        "https://firebasestorage.googleapis.com/v0/b/little-idiots.appspot.com/o/images%2Fwino-card-scene-simple.png?alt=media",
                        "Happy Birthday, wino."
                    )
                }
                imgRight={false}
            />
            <div ref={worksRef}>
                <HowItWorks scrollToTryIt={() => scrollToRef(tryItRef)} />
            </div>
            <div ref={tryItRef}>
                <Showcase
                    image={"https://firebasestorage.googleapis.com/v0/b/little-idiots.appspot.com/o/images%2Fone-job-card-scene-simple.png?alt=media"}
                    imageAlt="On your special day I have just one job."
                    headline={{
                        title: "Try It",
                        subtitle: "Pick any card and click 'Try It' to open the scene in AR.",
                        size: 2,
                        maxSubtitleWidth: 450,
                    }}
                    buttonText="Choose Card"
                    onButtonClick={() => history.push("/cards")}
                />
            </div>
        </div>
    )
}

export default Home
