// Custom hook for creating a responsiveness factor to resize for screen sizes

import { useState, useEffect } from 'react';
import useWindowSize from './useWindowSize';
import { breakpoints, resFactors } from '../components/shared/magicConstants';

export default function useResponseFactor() {
    const [resFactor, setResFactor] = useState(1.0);
    const { width } = useWindowSize();

    useEffect(() => {
        if (width < breakpoints.phone) {
            setResFactor(resFactors.phone);
        } else if (width < breakpoints.tablet) {
            setResFactor(resFactors.tablet);
        } else if (width < breakpoints.tabletLarge) {
            setResFactor(resFactors.tabletLarge)
        } else {
            setResFactor(1.0);
        }
    }, [width])

    return resFactor;
}
