import React from 'react'
import useWindowSize from '../../hooks/useWindowSize'

function MiniShowcase({ box, image, imgRight = true }) {

    // Height of this component, when it appears as a row, in pixels
    const ROW_HEIGHT = 500;
    // When 95% width is less than this value, break from row to colum
    const BREAK_WIDTH = 1100;

    const { width } = useWindowSize();

    // this div's 100% width is the lesser of .95 * screen width or 1500px
    const isRow = width * 0.95 > BREAK_WIDTH;

    const wrapStyle = {
        position: isRow ? "relative" : "static",
        width: isRow ? `${BREAK_WIDTH}px` : "100%",
        height: isRow ? `${ROW_HEIGHT}px` : "auto",
    }

    const imgStyle = {
        position: isRow ? "absolute" : "static",
        right: isRow && imgRight ? "0px" : null,
        left: isRow && !imgRight ? "0px" : null,
        height: isRow ? `${ROW_HEIGHT}px` : "auto",
        width: "auto",
        maxWidth: "100%",
    }

    const boxStyle = {
        position: isRow ? "absolute" : "static",
        right: isRow && !imgRight ? "0px" : null,
        left: isRow && imgRight ? "0px" : null,
    }

    return (
        <div
            className={`${isRow ? "row" : "col"}`}
            style={wrapStyle}
        >
            <div style={imgStyle}>
                {image}
            </div>
            <div style={boxStyle}>
                {box}
            </div>
        </div>
    )
}

export default MiniShowcase
