import React from 'react'
import useResponseFactor from '../../hooks/useResponseFactor';
import { fontSizes } from './magicConstants';

/**
 * Renders any combination of a headline, then subtitle, then footnote, as a
 * column of text.
 * @param title The headline (boldest)
 * @param subtitle Medium-level text (paragraph below headline)
 * @param footnote Small, light text at bottom
 * @param size 1, 2, or 3: large, medium, or small fonts. Default is medium.
 * @param textAlign Default is center. Does not apply to title, which is
 * always centered.
 * @param maxBoxWidth Maximum width in pixels for the whole component. Default 
 * is null (no max)
 * @param maxSubtitleWidth Maximum width in pixels for just the subtitle and 
 * footnote. Default is 400.
 */
function Headline({
    title = "",
    subtitle = "",
    footnote = "",
    size = 2,
    textAlign = "center",
    maxBoxWidth,
    maxSubtitleWidth = 400,
}) {
    // Responsiveness factor by which to resize fonts based on screen size
    const resFactor = useResponseFactor() || 1.0;
    // Returns component (title, subtitle, or footnote) font size adjusted for
    // size of Headline component (1, 2, or 3) and screen size (resFactor)
    const getFontSize = (component) => {
        let sz = fontSizes[component].medium || 40;
        switch (size) {
            case (1 || "large"):
                sz = fontSizes[component].large;
                break;
            case (3 || "small"):
                sz = fontSizes[component].small;
                break;
            default:
                break;
        }
        return `${sz * resFactor}px`;
    }
    const marginSize = "10px"; //size === 3 ? "5px" : "10px";
    const baseStyle = {
        width: "100%",
        textAlign: textAlign,
        whiteSpace: "break-spaces",
    }

    return (
        <div
            className="col"
            style={{
                maxWidth: maxBoxWidth ? `${maxBoxWidth}px` : null,
            }}
        >
            {title &&
                <h1
                    style={{
                        ...baseStyle,
                        fontFamily: "amity",
                        fontSize: getFontSize("title"),
                        marginBottom: (subtitle || footnote) && size !== 1 ? "10px" : "-" + marginSize,
                        fontWeight: "normal",
                        textAlign: "center",
                    }}
                >
                    {title}
                </h1>
            }
            {subtitle &&
                <h3
                    style={{
                        ...baseStyle,
                        fontSize: getFontSize("subtitle"),
                        fontWeight: "normal",
                        marginBottom: footnote ? marginSize : "0px",
                        maxWidth: `${maxSubtitleWidth}px`,
                    }}
                >
                    {subtitle}
                </h3>
            }
            {footnote &&
                <p
                    style={{
                        ...baseStyle,
                        fontSize: getFontSize("footnote"),
                        fontWeight: "lighter",
                        maxWidth: `${maxSubtitleWidth}px`,
                    }}
                >
                    {footnote}
                </p>
            }
        </div>
    )
}

export default Headline
