// Returns the user's os as a string: 
// "Android" for Android
// "iOS" for iOS that supports AR
// "unknown" for all others (windows, desktop, iOS that does not suport AR; ie iOS 12 or earlier)
export function os() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return "unknown";
    }

    if (/android/i.test(userAgent) && userAgent.indexOf("android") !== -1) {
        return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (isiOSDevice() || isiPad()) {
        if (tryiOSScene()) {
            return "iOS";
        }
        return "unknown"
    }

    return "unknown";
}

// Returns true if iOS device; false otherwise
// HOWEVER will not return true for iPads running iOS 13 (or presumably but not definitely 14+)
function isiOSDevice() {
    return (/iPad|iPhone|iPod/.test(navigator.platform));
}

// Returns true if iPad running iOS 13 (or presumably but not definitely 14+)
function isiPad() {
    return (navigator.maxTouchPoints &&
        navigator.maxTouchPoints > 2 &&
        /MacIntel/.test(navigator.platform));
}

// Returns true if device supports AR AND is not iOS 11 or 12 (or less)
// Only try the iOS scene instead of the fallback if this returns true
function tryiOSScene() {
    return iOSSupportsAR() && !iOSIs12OrLess();
}

// Returns true if this version of iOS supports AR
function iOSSupportsAR() {
    const a = document.createElement("a");
    return (a.relList.supports("ar"));
}

// Returns true if iOS version is <= 12
function iOSIs12OrLess() {
    // This is true for iPads running 13 (and presumably higher)
    // Version parsing algorithm below won't work on these iPads
    if (isiPad()) {
        return false;
    }
    var version;
    if (/iP(hone|od|ad)/.test(navigator.platform)) {
        var v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
        version = [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
    }
    return version[0] <= 12;
}