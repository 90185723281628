// Renders the responsive navbar at the top of the site

import React from "react";
import './navbar.css'
import logo from '../../../images/navLogo.png';
import { Link } from 'react-router-dom';
import NavLinks from "./NavLinks";

function NavBar() {
    return (
        <>
            <nav className="row navbar" style={{ zIndex: "1000" }}>
                <Link to="/" className="navbar-logo">
                    <img src={logo} alt="main logo" />
                </Link>
                <div className="desktop-nav">
                    <NavLinks closeMenu={false} />
                </div>
            </nav>
        </>
    )
}

export default NavBar
