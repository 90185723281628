import React from 'react'
import Button from '../../shared/Button'
import Carousel from '../../shared/Carousel'
import Headline from '../../shared/Headline'
import { useHistory } from 'react-router-dom'
//import ModelViewer from '../../shared/ModelViewer'
import { cardBackLink, BACKGROUND_COLOR } from '../../shared/magicConstants'
import placeholder from '../../../images/placeholder.png'

function CardView({ card, width = 400 }) {
    const etsyLink = card.etsyLink || "https://www.etsy.com/shop/LittleIdiotsAR";
    const cardPage = card.cardPage || ""

    const imgArray = () => {
        const images = card.images?.map(i => {
            return <img src={i} alt={card.alt} />
        }) || [placeholder];
        //images.push(<ModelViewer cardObj={card} />);
        images.push(<img src={cardBackLink} alt="Back view of card" />)
        return images;
    }

    // The card view is a rectangle with a default size of 400x550px
    // The top section with images is a square (400x400); the bottom white box
    // is a rectangle (150x400). These sizes are calculated based on width,
    // which scales down for tablets and phones.
    const totalHeight = width * 1.5// 1.375;
    const whiteBoxHeight = width * 0.5 // * 0.375

    const openEtsyLink = () => {
        window.open(etsyLink, "_blank", "noopener");
    }

    const history = useHistory();
    const openCardPage = () => {
        history.push(`/cards/${cardPage}`);
    }

    return (
        <div
            className="col"
            style={{
                minHeight: `${totalHeight}px`,
                width: `${width}px`,
                marginBottom: "50px",
                marginTop: "20px",
            }}
        >
            <div
                style={{
                    height: `${width}px`,
                    width: `${width}px`,
                    backgroundColor: BACKGROUND_COLOR,
                }}
            >
                <Carousel
                    items={imgArray()}
                />
            </div>
            <div
                className="col"
                style={{
                    minHeight: `${whiteBoxHeight}px`,
                    width: `${width}px`,
                    backgroundColor: "white",
                    padding: "10px",
                    justifyContent: "center",
                }}
            >
                <Headline
                    title={card.title || "Little Idiots"}
                    size={3}
                    subtitle={card.subtitle}
                />
                <div
                    className="row"
                    style={{ justifyContent: "center" }}
                >
                    <Button
                        text={card.price || "Buy It"}
                        onClick={openEtsyLink}
                        size="small"
                        darkBg={true}
                        pill={true}
                    />
                    <Button
                        text="Try It"
                        onClick={openCardPage}
                        size="small"
                        darkBg={false}
                        pill={true}
                    />
                </div>
            </div>
        </div>
    )
}

export default CardView