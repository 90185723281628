import React from 'react'
import { RenderRoutes } from '../../routing/Routes'
import { cardRoutes } from '../../routing/cardRouteConfig'

function CardsWrap() {
    return (
        <div>
            <RenderRoutes routes={cardRoutes()} />
        </div>
    )
}

export default CardsWrap
