import Card from '../pages/card/Card'
import Cards from '../pages/browseCards/Cards'
import { cards } from '../shared/fakeBackEnd'

export const cardRoutes = () => {
    const result = [
        {
            path: "/cards",
            key: "cards",
            exact: true,
            component: Cards,
        },
        {
            path: "/demos",
            key: "demos",
            exact: true,
            component: Cards,
        },
    ];

    cards.forEach(c => {
        result.push({
            path: `/cards/${c.cardPage}`,
            key: `${c.cardPage}`,
            exact: true,
            component: getCard(c),
        })
    })

    return result;
}

const getCard = (cardObj) => {
    const cardComp = () => {
        return (
            <Card
                cardObj={cardObj}
            />
        )
    }
    return cardComp;
}