import Home from '../pages/home/Home'
import CardsWrap from '../pages/browseCards/CardsWrap'
import Help from '../pages/help/Help'
// import Admin from '../pages/admin/Admin'

/**
 * List of route objects containing path ("/path"), key (string), exact (bool), 
 * component (React component).
 */
export const appRoutes = [
    {
        path: "/",
        key: "home",
        exact: true,
        component: Home,
    },
    {
        path: "/cards",
        key: "cards",
        exact: false,
        component: CardsWrap,
    },
    {
        path: "/demos",
        key: "demos",
        exact: true,
        component: CardsWrap,
    },
    {
        path: "/help",
        key: "help",
        exact: true,
        component: Help,
    },
    // {
    //     path: "/admin",
    //     key: "admin",
    //     exact: false,
    //     component: Admin,
    // }
]
